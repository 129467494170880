import React from 'react';
import { Link } from 'react-router-dom';

import check_mark from './images/check_mark.jpg';
import name_oregon from './images/name_oregon.jpg';
import chain_type_oregon from './images/chain_type_oregon.jpg';
import drive_link from './images/drive_link.jpg';
import type_oregon from './images/type_oregon.jpg';


const SawType = () => {

  const scrollToAnchroingPosition = (id) => {
    const anchor = document.getElementById(id);
    if (anchor) {
      window.scrollTo({ top: anchor.offsetTop, behavior: 'smooth' });
    }
  };

  return (
    <div>
    <h2 className="guide mb-5 ps-3">ソーチェンの選び方</h2>
    <p>交換したいソーチェンをお手元にご用意ください。</p>

    <section className="m-lg-5">
      <h4 className="step py-4 ps-3">Step1.ソーチェンのメーカーをお選びください</h4>
      <h5 className="mt-5 vertical_line">ソーチェンの見分け方</h5>
      <ul className="engraving">
        <li className="py-4">
          <img src={check_mark} alt="" className="check_mark" />
          <p>つなぎの部分に「OREGON」または「O」と記載があるものはオレゴン社製ソーチェンです。</p>
          <img src="img/engraving_oregon.jpg" alt="" />
          <button className="common_btn my-3 chain_btn" onClick={() => scrollToAnchroingPosition('oregon')}>
            オレゴンソーチェンへ
          </button>
        </li>
        <li className="py-4">
          <img src={check_mark} alt="" className="check_mark" />
          <p>つなぎの部分に「Husqv」または「H」と記載があるものはハスクバーナ社製ソーチェンです。</p>
          <img src="img/engraving_husqv.jpg" alt="" />
          <button className="common_btn my-3 chain_btn" onClick={() => scrollToAnchroingPosition('husqv')}>
            ハスクバーナソーチェンへ
          </button>
        </li>
        <li className="py-4">
          <img src={check_mark} alt="" className="check_mark" />
          <p>刃の部分に「STIHL」と記載があるものはスチール社製ソーチェンです。</p>
          <img src="img/engraving_stihl.jpg" alt="" />
          <button className="common_btn my-3 chain_btn" onClick={() => scrollToAnchroingPosition('stihl')}>
            スチールソーチェンへ
          </button>
        </li>
      </ul>
    </section>

    <section id="oregon" className="m-lg-5">
      <img src={name_oregon} alt="オレゴンソーチェン" className="chain" />
      <h4 className="step py-4 ps-3">Step2.刻印された数字を確認してください</h4>
      <img src={chain_type_oregon} alt="" />
      <button className="common_btn blade" onClick={() => console.log('詳細を見る')}>
        刃の種類を詳しく見る
      </button>

      <h4 className="step py-4 ps-3">Step3.ドライブリンクの数を数えてください</h4>
      <img src={drive_link} alt="ドライブリンク数" />
      <div className="next">
        <img src={type_oregon} alt="品番" />
        <Link to="/sawgokan" className="d-inline-block compatibility">
          オレゴンソーチェンと互換性のあるソーチェンを知りたい方はこちら
        </Link>
      </div>
    </section>

    {/* ここにhusqvとstihlのセクションも追加 */}

  </div>
  );
};

export default SawType;