import React from 'react';
import { Link } from 'react-router-dom';

import pitch_chain from './images/pitch_chain.jpg';
import gauge_chain from './images/gauge_chain.jpg';
import drive_link from './images/drive_link_info.jpg';
import guide_bar from './images/guide_bar.jpeg';



const SawInfo = () => {
  return (
    <><h2 className="info mb-5 ps-3">ソーチェンの基礎知識</h2><section className="pb-5">
      <h3 className="mb-3 py-4 ps-3 text-white">ソーチェンとは</h3>
      <p>
        <span style={{ fontSize: '24px' }}>
          ソーチェンとは、<span className="info_text">「チェーンソーの刃の部分」</span>のことを言います。
        </span>
        <br />
        主に木材の伐採、枝の剪定に使われ、上刃と横刃で木を削っていきます。チェンソーの部品の中で一番消耗が激しく、切れ味が悪くなったまま使っていると、余計な力が加わりキックバック等が起こりやすくなり怪我のリスクも高まります。
        <br />
        ソーチェンはその性能やコスト、使いやすさなどによって種類も様々で、使用用途や目的に応じて選んでいただけます。
      </p>
    </section><section className="pb-5">
        <h3 className="mb-3 py-4 ps-3 text-white">ソーチェンの規格について</h3>
        <p>
          ソーチェンにはチェーンタイプ、チェーンピッチ、ゲージ、ドライブリンク数という４つの規格があります。<br />
          チェーンピッチ、ゲージ幅についてはほぼ全てのチェンソーで共通の規格となっています。
          ドライブリンク数は各チェンソーの種類によって異なる場合があります。
        </p>
        <div className="my-5">
          <h4>チェーンタイプ</h4>
          <p>
            チェーンタイプはカッターの形状のことで、チェーンとガイドバーをつなぐドライブリンク部分に刻印されている番号で判断できます。<br />
            メーカーによって表記が異なりますが、互換表と照らし合わせていただくことでそれぞれの適合チェーンに変換することができます。
          </p>
          <Link to="/saw-gokan" className="link_gokan">
            互換表はこちら
          </Link>
        </div>

        <div className="mb-5">
          <h4>ピッチ</h4>
          <p>
            チェーンのサイズを決める一つの単位です。ピッチによって大まかなソーチェンの種類が分かります。<br />
            ソーチェンの３個の連続したリベットの距離を２で割った数値で表されます。<br />
            どのチェンソーにも適合するチェーンのサイズがあり、主に1/4"、.325"、3/8"、.404"の４つの規格があります。
          </p>
          <img src={pitch_chain} alt="ピッチ" style={{ width: '400px' }} />
        </div>

        <div className="mb-5">
          <h4>ゲージ</h4>
          <p>
            ゲージとはドライブリンクの厚みを指します。ゲージ幅はガイドバーの溝の幅で決まります。<br />
            ガイドバーの溝幅が狭すぎても広すぎてもいけません。
          </p>
          <p>■ゲージ幅の規格</p>
          <img src={gauge_chain} alt="ゲージ" style={{ width: '700px' }} />
        </div>

        <h4>ドライブリンク数</h4>
        <p>ソーチェン一周のドライブリンクの数です。コマ数とも呼ばれます。</p>
        <img src={drive_link} alt="ドライブリンク数" style={{ width: '700px' }} />
      </section><section className="py-5">
        <h3 className="mb-3 py-4 ps-3 text-white">バーサイズの測り方</h3>
        <p>
          チェーンソーのガイドバーの長さ(有効切断長)を知るためには、チェーンソー本体の前部からガイドバーの先端にかかるカッターの先端までの長さを測ります。<br />
          ガイドバー全体の長さではありませんので、注意が必要です。
        </p>
        <img src={guide_bar} alt="バーサイズの測り方" />
      </section></>
  );
};

export default SawInfo;